import React from "react";
import Seo from "../../../components/seo";
import Industry from "../../../layouts/Industries/Industry";
import {graphql, useStaticQuery} from 'gatsby';
import Layout from "../../../components/layout";
import {Pharmacies} from '../../../components/svgs/industries';

const OthersIndustriesPageEn = () => {
  const data = useStaticQuery(graphql`
    query OthersIndustriesDatasEn {
      allContentfulSecteurs(filter: {contentful_id: {eq: "70OA8GcVMObd2NwScKZ0IC"}}) {
        edges {
          node {
            name
            permalink
            contentful_id
            advantages {
              title
              description
              icon
              visual {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
            installations {
              gatsbyImageData(layout: CONSTRAINED)
            }
            clients {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  return (
    <Layout 
    slider={false} 
    switchLink={data.allContentfulSecteurs.edges[0].node.permalink} 
    lang="en-CA"
    title={data.allContentfulSecteurs.edges[1].node.name} 
    subTitle="Digital signage in"
    icon={<Pharmacies/>}>
      <Seo title="Creation and Management of Dynamic Digital Display"
           description="Content and design adapted to your industry, use our business screen solution to easily share everything you have in mind. Choose Attractif for your digital display needs."
           lang="en-CA"/>
      <Industry lang="en-CA" datas={data.allContentfulSecteurs.edges[1].node}/>
    </Layout>
  )
}

export default OthersIndustriesPageEn
